import classNames from 'classnames';

export interface ShareButtonProps {
    url: string;
    className?: string;
    onClose?: () => void;
    onClick?: (onClick: () => void) => void;
}

export default function ShareButton({
    url,
    onClose,
    className,
    children,
    onClick: onClickCustom,
}: React.PropsWithChildren<ShareButtonProps>) {
    const onClick = () => {
        const shareWindow = window.open(url, 'Share', 'popup,width=400,height=550');

        if (shareWindow && onClose) {
            const interval = setInterval(() => {
                try {
                    if (shareWindow === null || shareWindow.closed) {
                        clearInterval(interval);
                        onClose();
                    }
                } catch (e) {
                    console.error(e);
                }
            }, 1000);
        }
    };

    return (
        <button
            onClick={onClickCustom ? () => onClickCustom(onClick) : onClick}
            className={classNames('button-reset', className)}
        >
            {children}
        </button>
    );
}
