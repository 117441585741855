import { Button, Row } from '.';

const COLOR = '#1DA0F1';

export default function TwitterButton({ children, ...props }) {
    return (
        <Button {...props} fillColor={COLOR} textColor="#fff">
            <Row>
                <svg viewBox="0 0 20 18">
                    <use href="/img/sprite.svg#twitter"></use>
                </svg>
            </Row>
            {children}
        </Button>
    );
}
