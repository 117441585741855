import { constructUrl } from '../utils/url';

export enum SocialMedia {
    TWITTER,
    LINKEDIN,
    FACEBOOK,
}

type TwitterParams = {
    type: SocialMedia.TWITTER;
    url: string;
    text?: string;
};
type LinkedinParams = {
    type: SocialMedia.LINKEDIN;
    url: string;
    title?: string;
    summary?: string;
};
type FacebookParams = {
    type: SocialMedia.FACEBOOK;
    url: string;
    quote?: string;
};
type GenerateShareUrlParams = TwitterParams | LinkedinParams | FacebookParams;

const SocialMediaUrl: Record<SocialMedia, string> = {
    [SocialMedia.FACEBOOK]: 'https://www.facebook.com/sharer/sharer.php',
    [SocialMedia.LINKEDIN]: 'https://linkedin.com/shareArticle',
    [SocialMedia.TWITTER]: 'https://twitter.com/share',
};
const ParamsMapper: Record<SocialMedia, (params: GenerateShareUrlParams) => Record<string, string | undefined>> = {
    [SocialMedia.FACEBOOK]: (params: FacebookParams) => ({ u: params.url, quote: params.quote }),
    [SocialMedia.LINKEDIN]: (params: LinkedinParams) => ({
        url: params.url,
        title: params.title,
        summary: params.summary,
        mini: 'true',
    }),
    [SocialMedia.TWITTER]: (params: TwitterParams) => ({ url: params.url, text: params.text }),
};

export function generateShareUrl(params: GenerateShareUrlParams) {
    const baseUrl = SocialMediaUrl[params.type];
    const mapper = ParamsMapper[params.type];

    const urlParams = mapper(params);
    return constructUrl(baseUrl, urlParams);
}
