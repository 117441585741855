import classNames from 'classnames';

interface CalendarIconProps {
    className?: string;
}

export default function CalendarIcon({ className }: CalendarIconProps) {
    return (
        <svg
            className={classNames('icon', 'icon-calendar', className)}
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            stroke="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.16667 4.33333V1M11.8333 4.33333V1M4.33333 7.66667H12.6667M2.66667 16H14.3333C15.2538 16 16 15.2538 16 14.3333V4.33333C16 3.41286 15.2538 2.66667 14.3333 2.66667H2.66667C1.74619 2.66667 1 3.41286 1 4.33333V14.3333C1 15.2538 1.74619 16 2.66667 16Z"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
