import { formatEventDate } from '@gn/core/event/utils';
import { appOrigin } from 'configs/url';
import { constructUrl } from '@gn/core/utils/url';
import { DIGITAL_BADGES_OG_IMAGE } from '@gn/core/config';
import { Brand } from '@gn/db/client';

export const TWEET_LIMIT = 280;
export const sanitizeConfig = {
    allowedTags: ['br', 'p', 'div', 'span'],
};

export function getBadgeUrl(user, event, full?) {
    if (full) {
        return appOrigin
            ? `${appOrigin}${getBadgeUrl(user, event)}`
            : `http://localhost:3000${getBadgeUrl(user, event)}`;
    }

    return `/badges/${event.slug}/${user.nickname ?? user.id}`;
}

export function getImageUrl(user, targetUrl?, from?, background?) {
    const slug = user.nickname ?? user.id;
    const updatedAtInMs = user.updatedAt ? new Date(user.updatedAt).getTime() : null;

    const searchParams = new URLSearchParams();
    from && searchParams.append('from', from);
    // encode parameter as og-image rep decodes twice (first - via url parse, second - explicitly with decodeURIComponent)
    background && searchParams.append('background', encodeURIComponent(background));

    return constructUrl(`${DIGITAL_BADGES_OG_IMAGE}${slug ? `/${slug}` : ''}`, {
        date: updatedAtInMs?.toString(),
        appendParam: searchParams.toString(),
        targetUrl: targetUrl && encodeURIComponent(targetUrl),
        version: '2',
    });
}

export function generateOgDescription(event) {
    return (
        `Join ${event.attendeesExpected ?? 'thousands of'} engineers worldwide at ${event.name} ` +
        `and meet ${event.speakersExpected ?? 'many'} top speakers at ${formatEventDate(event)}`
    ).replace(/ +/g, ' ');
}

const FONT_WEIGHTS = [400, 500, 700];
const IGNORED_FONTS = ['Arial', 'Helvetica', 'GothamPro', 'Gotham Pro', 'sans-serif', 'monospace'];
const RE = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
export async function fetchGoogleFonts(event: { brand: Pick<Brand, 'font'> }) {
    const params = event.brand.font?.split(',').reduce((acc, font) => {
        if (!IGNORED_FONTS.includes(font.trim())) {
            acc.append('family', `${font.trim()}:wght@${FONT_WEIGHTS.join(';')}`);
        }

        return acc;
    }, new URLSearchParams());

    if (params && params.has('family')) {
        const googleFontLink = `https://fonts.googleapis.com/css2?${params.toString()}&display=fallback`;
        const css = await fetch(googleFontLink, {
            method: 'GET',
            headers: {
                'User-Agent':
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36',
            },
        }).then((res) => res.text());
        const fonts = (css && css.match(RE)) || [];
        return { fonts, css };
    }

    return { fonts: [], css: null };
}

export function shouldSubscribeToEmails(event: { brand: { id: number } }) {
    return ![25].includes(event.brand.id); // do not subscribe non-devs to tech emails
}
