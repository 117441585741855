import React from 'react';
import { CalendarEvent, generateCalendarUrl } from '@gn/core/integrations/addevent';
import classNames from 'classnames';
import CalendarIcon from '../icons/CalendarIcon';

type AddEventButtonProps = {
    event: CalendarEvent;
    className?: string;
    onClick?: () => void;
};

const AddEventButton = ({ event, className, children, onClick }: React.PropsWithChildren<AddEventButtonProps>) => {
    const href = generateCalendarUrl(event);

    return (
        <a
            href={href}
            className={classNames('btn', className)}
            onClick={onClick}
            target="_blank"
            rel="noopener noreferrer nofollow"
        >
            <CalendarIcon className="icon-start icon-sm" />
            {children}
        </a>
    );
};

export default AddEventButton;
