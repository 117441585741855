import type { PropsWithChildren } from 'react';
import ShareButton, { ShareButtonProps } from './ShareButton';
import { generateShareUrl, SocialMedia } from '@gn/core/socials/utils';

interface LinkedinShareButtonProps extends ShareButtonProps {
    title?: string;
    summary?: string;
}

export default function LinkedinShareButton({
    className,
    title,
    summary,
    url,
    children,
}: PropsWithChildren<LinkedinShareButtonProps>) {
    // prepopulating text is no longer supported
    const shareUrl = generateShareUrl({ type: SocialMedia.LINKEDIN, url, title, summary });

    return (
        <ShareButton className={className} url={shareUrl}>
            {children}
        </ShareButton>
    );
}
