import { Brand, Event } from '@gn/db';
import { constructUrl, getBadgeEventUrl } from '../utils/url';
import { DIGITAL_BADGES_OG_IMAGE, TWEET_LIMIT } from '../config';
import format from 'date-fns/format';

export function getPreviewImageUrl({
    email,
    name,
    from,
    background,
    date,
    eventSlug,
}: {
    email?: string | null;
    name?: string | null;
    from?: string | null;
    background?: string | null;
    date?: Date | null;
    eventSlug: string;
}) {
    const updatedAtInMs = date ? date.getTime() : null;
    const confDomain = getBadgeEventUrl(eventSlug).replace('https://', '').replace('http://', '');

    const appendParam = new URLSearchParams();
    from && appendParam.append('from', from);
    // encode parameter as og-image rep decodes twice (first - via url parse, second - explicitly with decodeURIComponent)
    background && appendParam.append('background', encodeURIComponent(background));

    return constructUrl(`${DIGITAL_BADGES_OG_IMAGE}/badge-preview.png`, {
        date: updatedAtInMs?.toString(),
        appendParam: appendParam.toString(),
        email,
        name,
        confDomain,
    });
}

type GenerationOptions = { hashtag?: boolean; url?: boolean };
type GetSocialSharedTextEvent = Pick<Event, 'name' | 'attendeesExpected' | 'speakersExpected' | 'hashtag'>;

export function getSocialSharedText(
    event: GetSocialSharedTextEvent & {
        brand: Pick<Brand, 'sharedTextTemplate'>;
    },
    badgeUrl: string,
    options: GenerationOptions = {},
) {
    let mainText: string;

    if (event.brand?.sharedTextTemplate) {
        mainText = event.brand.sharedTextTemplate;
    } else {
        mainText = generateSocialSharedText(event, badgeUrl);
    }

    const parts = [mainText];
    if (options.url && badgeUrl && !mainText.includes(badgeUrl)) {
        parts.push(badgeUrl);
    }
    if (options.hashtag && event.hashtag && !mainText.includes(`#${event.hashtag}`)) {
        parts.push(`#${event.hashtag}`);
    }

    return parts.join('\n');
}

export function generateSocialSharedText(event: GetSocialSharedTextEvent, badgeUrl: string) {
    const textParts = [
        `I'm attending ${event.name} - get your free remote ticket and join me there`,
        `with ${event.attendeesExpected ?? 'thousands of'} other engineers`,
        `and ${event.speakersExpected ?? 'many'} great speakers`,
    ];

    // build text considering tweet limit
    let finalText = '';
    for (let i = 0; i < textParts.length; i++) {
        const current = finalText.concat(' ').concat(textParts[i]);
        const currentJoined = [current, badgeUrl, event.hashtag ? `#${event.hashtag}` : ''].join('\n');

        if (currentJoined.length > TWEET_LIMIT) {
            break;
        }
        finalText = current;
    }

    return finalText.trim().replace(/ +/g, ' ');
}

export function generateGeoBadgeText(event: Pick<Event, 'name' | 'startDate' | 'location'>) {
    const place = event.location
        ? `in ${event.location!.split(/\+|&/)[0].trim()} for ${event.name}`
        : `at ${event.name}`;
    const date = format(event.startDate!, 'MMM dd');

    return `Excited to be ${place} on ${date}. Let's hang out! Can't make this in person? Register for free with this badge and join 50% of talks remotely.`;
}

export const getFormattedId = (id: number) => {
    return ('00000' + String(id)).slice(-5);
};
