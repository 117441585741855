import type { PropsWithChildren } from 'react';
import ShareButton, { ShareButtonProps } from './ShareButton';
import { SocialMedia, generateShareUrl } from '@gn/core/socials/utils';

interface FacebookShareButtonProps extends ShareButtonProps {
    quote?: string;
}

export default function FacebookShareButton({
    className,
    quote,
    url,
    children,
}: PropsWithChildren<FacebookShareButtonProps>) {
    const shareUrl = generateShareUrl({ type: SocialMedia.FACEBOOK, url, quote });
    return (
        <ShareButton className={className} url={shareUrl}>
            {children}
        </ShareButton>
    );
}
