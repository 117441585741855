import { generateShareUrl, SocialMedia } from '@gn/core/socials/utils';
import type { PropsWithChildren } from 'react';
import ShareButton, { ShareButtonProps } from './ShareButton';

interface TwitterShareButtonProps extends ShareButtonProps {
    title: string;
}

export default function TwitterShareButton({
    className,
    title,
    url,
    children,
}: PropsWithChildren<TwitterShareButtonProps>) {
    const shareUrl = generateShareUrl({ type: SocialMedia.TWITTER, url, text: title });
    return (
        <ShareButton className={className} url={shareUrl}>
            {children}
        </ShareButton>
    );
}
