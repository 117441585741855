import { Button, Row } from '.';

const COLOR = '#1877F2';

export default function FacebookButton({ children, ...props }) {
    return (
        <Button {...props} fillColor={COLOR} textColor="#fff">
            <Row>
                <svg>
                    <use href="/img/sprite.svg#facebook"></use>
                </svg>
            </Row>
            {children}
        </Button>
    );
}
